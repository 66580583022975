import React from 'react';

import {Card, CardBody, Col, Container, Row} from 'reactstrap';
import Breadcrumbs from '../../components/Common/Breadcrumb';

const ContactUs = () => {
    document.title = "AiBizChats.com | Contact Us";

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid={true}>
                    <Breadcrumbs title="Dashboard" url="/my-bots" breadcrumbItem="Contact Us" />
                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardBody>
                                    <p>
                                        For questions or support contact <a href="mailto:support@albanydatasystems.com">support@albanydatasystems.com</a>
                                    </p>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment >
    );
};

export default ContactUs;