import React from "react";
import { Link } from "react-router-dom";
import {
    Button,
    Card,
    CardBody,
    Col,
    Container,
    DropdownItem,
    DropdownMenu,
    DropdownToggle, Modal,
    Row, Toast, ToastBody, ToastHeader,
    UncontrolledDropdown,
} from "reactstrap";
import {getDataIndexes, newDataIndex, apiRoot} from "../../Api";
// Import User Grid Data
import { useNavigate } from "react-router-dom";


//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import Select from "react-select";
import logosm from "../../assets/images/logo.png";

const UserGrid = () => {


      const navigate = useNavigate();


    document.title = "AiBizChats.com | My Bots";
    let [myBots, setMyBots] = React.useState([]);
    let [show_new_bot_modal, setshow_new_bot_modal] = React.useState(false);
    let[isAdding, setIsAdding] = React.useState(false);
    let [botName, setBotName] = React.useState("");
    let [openAiAssistantKey, setOpenAiAssistantKey] = React.useState("");
    let [botPersonality, setBotPersonality] = React.useState("");
    let [botGreeting, setBotGreeting] = React.useState("");

    let [isLoading, setIsLoading] = React.useState(true);
    React.useEffect(() => {
        getDataIndexes().then((res) => {
            setMyBots(res);
            setIsLoading(false);
        });
    }, []);


      function tog_show_new_bot_modal() {
        setshow_new_bot_modal(!show_new_bot_modal);
      }


    return (
        <React.Fragment>

            <div className="page-content">




                <Container fluid={true}>
                    <Breadcrumbs title="Dashboard" url="/my-bots" breadcrumbItem="My Chatbots" />


                                              {               isAdding ?          <Toast style={{zIndex:238238, margin: "0 auto"}} isOpen={isAdding !== null} className="toast fade show" role="alert">
                                                    <ToastHeader toggle={()=>setIsAdding(null)} className="toast-header">
                                                        <img src={logosm} alt="" className="me-2" height="18" />

                                                    </ToastHeader>
                                                    <ToastBody>
                                                        {isAdding ? "Creating your chatbot..." : null}
                                                    </ToastBody>
                                                </Toast> : null}

                    <Row>
                         <Col xl={3} sm={6} >
                             <button type="button" className="btn btn-success text-truncate "
                                onClick={()=>{
                                    setshow_new_bot_modal(true)}}
                             >
                                 <i className="bx bx-plus align-middle"></i> Add New Chatbot
                             </button>

                         </Col>
                    </Row>
                    <hr></hr>
                    <Row>
                        {myBots.map((item, key) => (
                            <Col xl={3} sm={6} key={key}>
                                <Card style={{height: "190px", cursor: "pointer"}}
                                      onClick={()=>{
                                        navigate("/ai-manager", { state: { dataIndexId: item.id } });
                                    }}
                                    onMouseEnter={(e)=>{
                                       // e.target.style.border = 'black 1px solid';
                                    }}
                                      onMouseLeave={(e)=>{
                                        //e.target.style.border="";
                                      }}
                                >
                                    <CardBody className={"simple-hover"}>

                                        <div className="d-flex align-items-center">
                                            {item.icon ? (
                                                <div>
                                                    <img
                                                        src={apiRoot + item.icon}
                                                        alt=""
                                                        className="avatar-md rounded-circle img-thumbnail"
                                                    />
                                                </div>
                                            ) : (
                                                <div className="avatar-md">
                                                    <div className="avatar-title bg-soft-primary text-primary h1 m-0 rounded-circle">
                                                        <i className="bx bxs-user-circle"></i>
                                                    </div>
                                                </div>
                                            )}

                                            <div className="flex-1 ms-3">
                                                <h5 className="font-size-16 mb-1">
                                                    <Link to="#" className="text-dark">
                                                        {item.name}
                                                    </Link>
                                                </h5>
                                                <p className="text-muted mb-0">Public Name: {item.public_name}</p>
                                            </div>
                                        </div>
                                        <p className="mt-3 text-muted">
                                            {item.description ? item.description : <br></br>}
                                        </p>


                                    </CardBody>
                                </Card>
                            </Col>
                        ))}
                    </Row>
                    <Row className="mt-3">
                        <Col xl={12}>
                            <div className="text-center mb-3">
                                <Link to="#" className="text-primary">
                                    {
                                        isLoading ? <React.Fragment><i className="mdi mdi-loading mdi-spin font-size-20 align-middle me-2"></i> Loading Your Chatbots...</React.Fragment> : ""
                                    }


                                </Link>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>

      <Modal id="myModal"
        isOpen={show_new_bot_modal}
        toggle={() => {
          tog_show_new_bot_modal();
        }}
      >

        <div className="modal-header">
          <h5
            className="modal-title"
            id="myModalLabel"
          >
            Add New Bot
          </h5>
          <button
            type="button"
            className="btn-close"
            onClick={() => {
              setshow_new_bot_modal(false);
            }}
            aria-label="Close"

          >

          </button>
        </div>
        <div className="modal-body">

            <label>Name</label>
            <input type="text" className="form-control" placeholder="Bot Name"
                onChange={(e) => {
                    setBotName(e.target.value)
                }}
            />
            <br></br>
            <label>(optional) Open Ai Assistant API Key</label>
            <input type="text" className="form-control" placeholder=""
                onChange={(e) => {
                    setOpenAiAssistantKey(e.target.value)
                }}
            />


        </div>
        <div className="modal-footer">
          <button
            type="button"
            onClick={() => {
              tog_show_new_bot_modal();
            }}
            className="btn btn-secondary "
          >
            Close
          </button>
          <button
            type="button"
            className="btn btn-primary "
            onClick={() => {
                if(! botName || botName.length < 1) {
                    alert("Please enter a name for your chatbot")
                    return
                }
                setshow_new_bot_modal(false);
                setIsAdding(true);
                newDataIndex(
                    {
                        name: botName,
                        personality: botPersonality,
                        greeting: botGreeting,
                        assistant_api_key: openAiAssistantKey || null,
                    }
                ).then((res)=> {
                    console.log(res);
                    navigate("/ai-manager", { state: { dataIndexId: res.index.id } });

                });
            }}
          >
            Add Chatbot
          </button>
        </div>
      </Modal>            
        </React.Fragment>
    );
};

export default UserGrid;
