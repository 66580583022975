import {
    useLocation,
    useNavigate,
    useParams, useSearchParams
} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {getDataIndexForEmbed} from "../../Api";
import Chat from "./Chat";
import {getLoggedinUser} from "../../helpers/api_helper.js";
import "../../assets/scss/popup_bot.css";

const ChatEmbedBubble = () => {
    const {chat_token} = useParams();
    const [dataIndex, setDataIndex] = useState(null);


    useEffect(() => {
        getDataIndexForEmbed(chat_token).then((res) => {

            setDataIndex(res.data);
                let authUser = getLoggedinUser();
                if (res.data.private && authUser.user_token !== res.data.user_token) {
                   return (
                       <React.Fragment>
                           <p>Unauthorized</p>
                       </React.Fragment>
                   )
                }

        })
    }, []);
    if (!dataIndex) return (<div>Loading...</div>);
    return (
        <div className={"chat-bubble"} style={{overflow:"hidden"}}>
            <Chat dataIndex={dataIndex} isPublic={true} memoryKey={dataIndex.memory_key}></Chat>
        </div>
    );
}
export default ChatEmbedBubble;