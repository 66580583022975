import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  Label,
  Input,
  FormFeedback,
  Form, Toast, ToastHeader, ToastBody,
} from "reactstrap";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

//redux
import { useSelector, useDispatch } from "react-redux";

import withRouter from "../../components/Common/withRouter";

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb";

import avatar from "../../assets/images/users/avatar-1.jpg";
// actions
import { editProfile, resetProfileFlag } from "../../store/actions";
import {getUserSettings, saveUserSettings} from "../../Api";
import logosm from "../../assets/images/logo.png";

const UserProfile = () => {
  document.title = "AiBizChats.com | My Account";

  const dispatch = useDispatch();

  const [username, setusername] = useState("");
  const [email, setemail] = useState("");
  const [name, setname] = useState("");
  const [idx, setidx] = useState(1);

  const { error, success } = useSelector((state) => ({
    error: state.profile.error,
    success: state.profile.success,
  }));

  useEffect(() => {
    if (localStorage.getItem("authUser")) {
      const obj = JSON.parse(localStorage.getItem("authUser"));

      getUserSettings().then((res) => {
        setusername(res.data.username);
        setemail(res.data.email);

      })

      setTimeout(() => {
        dispatch(resetProfileFlag());
      }, 3000);
    }
  }, [dispatch, success]);

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      username: username || "",
      email: email || "",
      id: idx || "",
    },
    validationSchema: Yup.object({
      username: Yup.string().required("Please Enter Your username"),
        email: Yup.string().required("Please Enter Your email"),

    }),
    onSubmit: (values) => {
                saveUserSettings(validation.values, false).then((res) => {
                    console.dir(res);
                                         setShowMessage(res);
                                        setTimeout(() => {
                                            setShowMessage(null);
                                        }, 1750);

                        });
    },
  });

  const [showMessage, setShowMessage] = useState(null);

  return (
    <React.Fragment>


        <div className="page-content">
          <Container fluid>
            <Breadcrumb url="/my-bots" title="Dashboard" breadcrumbItem="My Account" />


            <h4 className="card-title mb-4">My Account</h4>
                <React.Fragment>
                        <Row>
                            <Col className="col-12">
                                <div className="page-title-box d-flex align-items-center justify-content-between">

                                    {showMessage ? <Toast
                                        style={{zIndex: 238238, margin: "auto", left: "40%", "position": "absolute"}}
                                        isOpen={showMessage !== null}
                                        className="toast fade show" role="alert">
                                        <ToastHeader toggle={() => setShowMessage(null)} className="toast-header">
                                            <img src={logosm} alt="" className="me-2" height="18"/>
                                        </ToastHeader>
                                        <ToastBody>
                                            {showMessage ? showMessage.message : null}
                                        </ToastBody>
                                    </Toast> : null}

                                </div>
                            </Col>
                        </Row>
                    </React.Fragment>
            <Card>
              <CardBody>
                <Form
                  className="form-horizontal"
                  onSubmit={(e) => {
                    e.preventDefault();
                    validation.handleSubmit();
                    return false;
                  }}
                >
                  <div className="form-group">
                    <Label className="form-label">User Name</Label>
                    <Input
                      name="username"
                      // value={name}
                        readOnly={true}
                      className="form-control"
                      placeholder="Enter User Name"
                      type="text"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.username || ""}
                      invalid={
                        validation.touched.username &&
                        validation.errors.username
                          ? true
                          : false
                      }
                    />
                    {validation.touched.username &&
                    validation.errors.username ? (
                      <FormFeedback type="invalid">
                        <div>{validation.errors.username}</div>
                      </FormFeedback>
                    ) : null}

                    <br></br>


                    <Label className="form-label">Email Address</Label>
                    <Input
                      name="email"
                      // value={name}
                      className="form-control"
                      placeholder="Enter Email Address"
                      type="text"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.email || ""}
                      invalid={
                        validation.touched.email &&
                        validation.errors.email
                          ? true
                          : false
                      }
                    />
                    {validation.touched.email &&
                    validation.errors.email ? (
                      <FormFeedback type="invalid">
                        <div>{validation.errors.email}</div>
                      </FormFeedback>
                    ) : null}

                    <br></br>
                    <Label className="form-label">Change Password</Label>
                    <Input
                      name="username"
                      // value={name}
                      className="form-control"
                      placeholder="Enter User Name"
                      type="password"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.password || ""}
                      invalid={
                        validation.touched.password &&
                        validation.errors.password
                          ? true
                          : false
                      }
                    />
                    {validation.touched.password &&
                    validation.errors.password ? (
                      <FormFeedback type="invalid">
                        <div>{validation.errors.password}</div>
                      </FormFeedback>
                    ) : null}
                    <Input name="idx" value={idx} type="hidden" />
                  </div>
                  <div className=" mt-4">
                    <Button type="submit" color="primary" onClick={()=>{
                      // TODO: save user settings
                        validation.handleSubmit();
                    }}>
                      Update
                    </Button>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Container>
        </div>
    </React.Fragment>
  );
};

export default withRouter(UserProfile);
