import React from 'react';
import { Navigate } from "react-router-dom";

// DashBoard
import Dashboard from '../Pages/Dashboard/Index';

// Calendar
import Calendar from '../Pages/Calendar/index';

// Chat
import AIManager from '../Pages/AIManager/AIManager';
import TOS from '../Pages/AIManager/TOS';
import ContactUs from '../Pages/AIManager/ContactUs'; 
import Disclaimers from '../Pages/AIManager/Disclaimers';
import PrivacyPolicy from '../Pages/AIManager/PrivacyPolicy';
import ChatEmbedScript from '../Pages/AIManager/ChatEmbedScript';
// Email
import Inbox from '../Pages/Email/Inbox';
import ReadEmail from '../Pages/Email/ReadEmail';

// Ecommerce
import AddProduct from '../Pages/Ecommerce/AddProduct';
import Cart from '../Pages/Ecommerce/Cart';
import Checkout from '../Pages/Ecommerce/Checkout';
import Customers from '../Pages/Ecommerce/EcommerceCustomer/Customers';
import Orders from '../Pages/Ecommerce/EcommerceOrder/Orders';
import ProductDetail from '../Pages/Ecommerce/ProductDetail';
import Product from '../Pages/Ecommerce/Products';
import Shops from '../Pages/Ecommerce/Shops';

// Invoices
import InvoicesDetail from '../Pages/Invoices/InvoiceDetails';
import InvoicesList from '../Pages/Invoices/InvoicesList';

// Contact
import UserGrid from '../Pages/Contacts/UserGrid';
import UserList from '../Pages/Contacts/UserList';
import ContactUserProfile from '../Pages/Contacts/UserProfile';

// UiElememnt
import UiAlerts from '../Pages/UIElements/UiAlerts';
import UiButtons from '../Pages/UIElements/UiButtons';
import UiCards from '../Pages/UIElements/UiCards';
import UiCarousel from '../Pages/UIElements/UiCarousel';
import UiDropdowns from '../Pages/UIElements/UiDropdowns';
import UiGrid from '../Pages/UIElements/UiGrid';
import UiImages from '../Pages/UIElements/UiImages';
import UiModals from '../Pages/UIElements/UiModals';
import UiOffcanvas from '../Pages/UIElements/UiOffcanvas';
import UiPlaceholders from '../Pages/UIElements/UiPlaceholders';
import UiProgressBars from '../Pages/UIElements/UiProgressBars';
import UiTabs from '../Pages/UIElements/UiTabs&Accordions';
import UiTypography from '../Pages/UIElements/UiTypography';
import UiVideo from '../Pages/UIElements/UiVideo';
import UiGeneral from '../Pages/UIElements/UiGeneral';
import UiColors from '../Pages/UIElements/UiColors';

// Extended Element
import ExLightbox from '../Pages/ExtendedElement/ExLightbox';
import ExRangSlider from '../Pages/ExtendedElement/ExRangeSlider';
import ExRating from '../Pages/ExtendedElement/ExRating';
import ExNotification from '../Pages/ExtendedElement/ExNotification';

// Forms
import BasicElements from '../Pages/Forms/BasicElements';
import Validation from '../Pages/Forms/Validation';
import AdvancedPlugins from '../Pages/Forms/AdvancedPlugins';
import Editors from '../Pages/Forms/Editors';
import FileUpload from '../Pages/Forms/FileUpload';
import Wizard from '../Pages/Forms/Wizard';
import Mask from '../Pages/Forms/Mask';

// Tables
import BootstrapBasic from '../Pages/Tables/BootstrapBasic';
import AdvanceTable from '../Pages/Tables/AdvanceTable';

// Charts
import ApexCharts from '../Pages/Charts/ApexCharts';
import ChartjsCharts from '../Pages/Charts/Chartjs';

// Icons
import FeatherIcons from '../Pages/Icons/Feathericon';
import BoxIcons from '../Pages/Icons/Boxicons';
import MaterialDesignIcons from '../Pages/Icons/MaterialDesign';
import DripIcons from '../Pages/Icons/Dripicons';
import FontAwesomeIcons from '../Pages/Icons/FontAwesome';

// Maps
import GoogleMaps from '../Pages/Maps/GoogleMaps';
import VectorMaps from '../Pages/Maps/VectorMaps';
import LeafletMaps from '../Pages/Maps/LeafletMaps';

// Inner Authentication Pages
import InnerLogin from "../Pages/InnerAuthPages/login";
import InnerRegister from "../Pages/InnerAuthPages/Register";
import RecoverPassword from "../Pages/InnerAuthPages/RecoverPassword";
import LockScreen from "../Pages/InnerAuthPages/LockScreen";
import InnerLogout from "../Pages/InnerAuthPages/Logout";
import ConfirmMail from "../Pages/InnerAuthPages/ConfirmMail";
import EmailVerification from "../Pages/InnerAuthPages/EmailVerification";
import TwoStepVerification from "../Pages/InnerAuthPages/TwoStepVerification";

// Utility Pages
import StarterPage from '../Pages/Utility/pages-starter';
import MaintenancePage from '../Pages/Utility/pages-maintenance';
import ComingSoonPage from '../Pages/Utility/pages-comingsoon';
import TimelinePage from '../Pages/Utility/pages-timeline';
import FaqsPage from '../Pages/Utility/pages-faqs';
import PricingPage from '../Pages/Utility/pages-pricing';
import Error404 from '../Pages/Utility/pages-404';
import Error500 from '../Pages/Utility/pages-500';

// Import Authentication pages
import Login from "../Pages/Authentication/Login";
import ForgetPasswordPage from "../Pages/Authentication/ForgetPassword";
import Logout from "../Pages/Authentication/Logout";
import Register from "../Pages/Authentication/Register";
import UserProfile from "../Pages/Authentication/user-profile";
import MyBots from "../Pages/AIManager/MyBots";
import ChatEmbedBubble from "../Pages/AIManager/ChatEmbedBubble";
import ChatPage from "../Pages/AIManager/ChatPage";
import ChatEmbedDiv from "../Pages/AIManager/ChatEmbedDiv";
const authProtectedRoutes = [

  // dashboard
  //{ path: "/dashboard", component: <Dashboard /> },
  { path: "/my-bots", component: <MyBots /> },

  // Profile
  { path: "/userprofile", component: <UserProfile /> },




  // Chat
  { path: "/ai-manager", component: <AIManager /> },
  { path: "/terms-of-service", component: <TOS /> },
  { path: "/contact-us", component: <ContactUs /> },
  { path: "/disclaimers", component: <Disclaimers /> },
  { path: "/privacy-policy", component: <PrivacyPolicy /> },
  

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  {
    path: "/",
    exact: true,
    component: <Navigate to="/my-bots" />,
  },
];

const publicRoutes = [

  // Authentication Page
  { path: "/logout", component: <Logout /> },
  { path: "/login", component: <Login /> },
  { path: "/forgot-password", component: <ForgetPasswordPage /> },
  { path: "/register", component: <Register /> },

  // Inner Auth Pages  
  { path: "/auth-login", component: <InnerLogin /> },
  { path: "/auth-register", component: <InnerRegister /> },
  { path: "/auth-recoverpw", component: <RecoverPassword /> },
  { path: "/auth-lock-screen", component: <LockScreen /> },
  { path: "/auth-logout", component: <InnerLogout /> },
  { path: "/auth-confirm-mail", component: <ConfirmMail /> },
  { path: "/auth-email-verification", component: <EmailVerification /> },
  { path: "/auth-two-step-verification", component: <TwoStepVerification /> },

  // Utility Pages
  { path: "/pages-maintenance", component: <MaintenancePage /> },
  { path: "/pages-comingsoon", component: <ComingSoonPage /> },
  { path: "/pages-404", component: <Error404 /> },
  { path: "/pages-500", component: <Error500 /> },

  {path: '/chat/:chat_token', component: <ChatPage />},
  {path: '/chat_embed/:chat_token', component: <ChatEmbedDiv />},
  {path: '/chat_popup/:chat_token', component: <ChatEmbedBubble />},
  {path: '/chat/embed', component: <ChatEmbedScript />},
];

export { authProtectedRoutes, publicRoutes };
