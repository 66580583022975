import React from "react";

import { Link } from "react-router-dom";
import {UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem} from "reactstrap";
export default function DataSourceCard(props) {

    function getIcon(type) {
        switch (type) {
            case 'LinkDataSource':
                return 'fas fa-link';
            case 'TextDataSource':
                return 'fas fa-file-alt';
            case 'WordpressDataSource':
                return 'fab fa-wordpress-simple';
            case 'WebsiteDataSource':
                return 'fas fa-globe';
            case 'PdfDataSource':
                return 'fas fa-file-pdf';
            case 'CSVDataSource':
                return 'fas fa-file-csv';
            default:
                return 'fas fa-question';
        }
    }

    let iconClass = "rounded-circle avatar-sm " + getIcon(props.dataSource.type);
    return (
        <li onClick={props.onClick} key={props.dataSource.id}>
        <Link to="#">
            <div className="d-flex align-items-start">
                <div className="flex-shrink-0 me-3 align-self-center">
                    <div className="user-img online">
                    <div className="avatar-sm">
                        <span className="avatar-title rounded-circle bg-soft-primary text-primary">
                    <i style={{
                        margin: "0 auto",
                        width: "32%",
                        height: "45%",
                    }} className={iconClass}></i>
          
                        <img src=""  alt="" />
                       </span>
                       </div>
                    </div>
                </div>

                <div className="flex-grow-1 overflow-hidden align-self-center">
                    <h5 className="text-truncate font-size-14 mb-1 align-self-center">{props.dataSource.name}</h5>
                    <p className="text-truncate mb-0">{props.dataSource.created_date}</p>
                </div>

                <div className="flex-shrink-0">
                    <div className="font-size-11"></div>
                </div>
            </div>
        </Link>
    </li>
    );

}