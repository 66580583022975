const ChatEmbedScript = () => {


    return (

        <div>
            :)
        </div>

    )

}

export default ChatEmbedScript;