import React, { useState, useRef } from "react";

import { useLocation } from "react-router-dom";

import {
  Card,
  Col,
  Container,
  Row,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { Link } from "react-router-dom";

import { saveDataIndex } from "../../../Api";
import SimpleBar from "simplebar-react";

export default function SummaryEditor(props) {
  let [inputValues, setInputValues] = useState(props.dataIndex);
  if (props.dataIndex.id != inputValues.id) {
    setInputValues(props.dataIndex);
  }
  return (
    <div className="w-100 user-chat mt-1 mt-sm-0 ms-lg-3">
      <Card>
        <div className="p-3 px-lg-4 border-bottom">
          <Row>
            <Col md={4} className="col-6"></Col>
            <Col md={8} className="col-6">
              <h4>Edit Summary</h4>
            </Col>
          </Row>
        </div>

        <div className="px-lg-2">
          <SimpleBar className="chat-conversation p-3" data-simplebar>
            <React.Fragment>
              <Col className="col-12">
                <React.Fragment>
                  <label
                    className="form-label"
                    htmlFor="formrow-firstname-input"
                  >
                    Edit Summary
                  </label>
                  <textarea
                    onInput={(e) => {
                      setInputValues(() => {
                        let d = { ...inputValues };
                        d.summary = e.target.value;
                        return d;
                      });
                    }}
                    type="text"
                    rows="5"
                    className="form-control"
                    id="specificSizeInputGroupUsername"
                    placeholder="Enter Summary of The Data"
                    value={inputValues.summary}
                  />
                </React.Fragment>
              </Col>
              <div className="pt-3 chat-input-section">
                <Row>
                  <Col className="col-auto"></Col>
                </Row>
              </div>
            </React.Fragment>
          </SimpleBar>
        </div>

        <div className="p-3 chat-input-section">
          <Row>
            <Col className="col"></Col>
            <Col className="col-auto">
              <button
                type="submit"
                className="btn btn-primary chat-send w-md waves-effect waves-light"
                onClick={() => {
                  saveDataIndex(inputValues, false).then((res) => {
                    props.onSave(res);
                  });
                  console.log(inputValues);
                }}
              >
                <span className="d-none d-sm-inline-block me-2">Save</span>{" "}
                <i className="mdi mdi-content-save float-end"></i>
              </button>
            </Col>
          </Row>
        </div>
      </Card>
    </div>
  );
}
