

import React, { useState, useRef, useEffect } from 'react';
import Select from "react-select";

import { useLocation } from 'react-router-dom';

import { Card,  Col, Container, Row, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";

import {apiRoot, saveDataIndex} from '../../../Api';

import SimpleBar from "simplebar-react";
export default function PersonalityEditor  (props) {
    let [inputValues, setInputValues] = useState(props.dataIndex);
    let [iconImage, setIconImage] = useState(null);
    useEffect(() => {
      setInputValues(props.dataIndex);
    }, []);
      const inputFile = useRef(null)

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file)
      fileReader.onload = () => {
        resolve(fileReader.result);
      }
      fileReader.onerror = (error) => {
        reject(error);
      }
    })
  }

  const inputLabel = (label, helper) => {
    return (
                            <label
                      className="form-label"
                      htmlFor="formrow-firstname-input"
                    >
                                {label} - <i style={{color: "gray", fontSize: "12px"}}>{helper}</i>
                    </label>
    )
  }
    return (
      <div className="w-100 user-chat mt-1 mt-sm-0 ms-lg-3">
        <Card>
          <div className="p-3 px-lg-4 border-bottom">
            <Row>
              <Col md={4} className="col-6"></Col>
              <Col  className="col-12">
                <h4 className=''>Edit Name & Avatar</h4>
              </Col>
            </Row>
          </div>
  
          <div className="px-lg-2">
            <SimpleBar className="chat-conversation p-3" data-simplebar>
              <React.Fragment>
                <Col className="col-12">
                  <React.Fragment>
                      {inputLabel("Name", "Your chatbot name, visible only to you.")}

                    <input
                      onInput={(e) => {
                        setInputValues(() => {
                          let d = { ...inputValues };
                          d.name = e.target.value;
                          return d;
                        });
                      }}
                      type="text"
                      rows="5"
                      className="form-control mb-4"
                      id="specificSizeInputGroupUsername"
                      placeholder="Enter Name of Bot"
                      value={inputValues.name}
                    />
                      {inputLabel("Public Name", "The name of your chatbot that is visible to the public.")}

                    <input
                      onInput={(e) => {
                        setInputValues(() => {
                          let d = { ...inputValues };
                          d.public_name = e.target.value;
                          return d;
                        });
                      }}
                      type="text"
                      rows="5"
                      className="form-control mb-4"
                      id="specificSizeInputGroupUsername"
                      placeholder="Enter Name of Bot"
                      value={inputValues.public_name}
                    />
                      {inputLabel("Description", "(Optional) The description of your chatbot, visible only to you.")}

                    <input
                      onInput={(e) => {
                        setInputValues(() => {
                          let d = { ...inputValues };
                          d.description = e.target.value;
                          return d;
                        });
                      }}
                      type="text"
                      rows="5"
                      className="form-control mb-4"
                      id="specificSizeInputGroupUsername"
                      placeholder="Enter Description"
                      value={inputValues.description}
                    />
                      {inputLabel("Greeting", "The initial greeting that is seen for your chatbot text. Example: \"How can I help you today?\".")}

                    <textarea
                      onInput={(e) => {
                        setInputValues(() => {
                          let d = { ...inputValues };
                          d.greeting = e.target.value;
                          return d;
                        });
                      }}
                      type="text"
                      rows="5"
                      className="form-control mb-4"
                      id="specificSizeInputGroupUsername"
                      placeholder="Enter Greeting"
                      value={inputValues.greeting}
                    />
                      {inputLabel("Chatbot Knowledge", "Selecting \"Strict\" makes the chatbot use the data you provided. Selecting \"Wise\" allows the chatbot to use it's additional knowledge.")}

                    <Select
                        className="mb-2"
                    defaultValue={{"value": inputValues.personality, "label": inputValues.personality.toUpperCase()}}
                onChange={(e) => {
                  setInputValues(() => {
                    let d = { ...inputValues };
                    d.personality = e.value;
                    return d;
                  });
                }}
                options={[
                    { "value": "strict", "label": "Strict" },
                    { "value": "wise", "label": "Wise" },

                ]}
            >


            </Select>                    

                  </React.Fragment>
                </Col>
                <div className="pt-3 chat-input-section">
                  <Row>
                    <Col className="col-auto"></Col>
                  </Row>
                </div>
      
              <Col className="col-12">
                <React.Fragment>
                    {inputLabel("Chatbot Instructions for your Data", "You can give additional instructions to your chatbot here about how it should answer and behave.")}

                  <textarea
                    onInput={(e) => {
                      setInputValues(() => {
                        let d = { ...inputValues };
                        d.summary = e.target.value;
                        return d;
                      });
                    }}
                    type="text"
                    rows="5"
                    className="form-control mb-4"
                    id="specificSizeInputGroupUsername"
                    placeholder="Enter Summary of The Data"
                    value={inputValues.summary}
                  />
                </React.Fragment>
              </Col>
              <Col className="col-12">
                <React.Fragment>
                  <label
                    className="form-label"
                    htmlFor="formrow-firstname-input"
                  >
Edit Icon
                  </label>
                                                            {inputValues.icon || iconImage ? (
                                                <div>
                                                    <img
                                                        src={iconImage ? URL.createObjectURL(iconImage) :apiRoot +inputValues.icon}
                                                        alt=""
                                                        className="avatar-md rounded-circle img-thumbnail"
                                                    />
                                                </div>
                                            ) : (

                                                <div className="avatar-md">
                                                    <div className="avatar-title bg-soft-primary text-primary h1 m-0 rounded-circle">
                                                        <i className="bx bxs-user-circle"></i>
                                                    </div>
                                                </div>
                                            )}
<br></br>
                    <input type='file' id='file' ref={inputFile} style={{display: 'none'}} onChange={(e)=>{
                        setIconImage(e.target.files[0])
convertBase64(e.target.files[0]).then((res)=> {
                        setInputValues(() => {
                          let d = { ...inputValues };
                          d.icon = res;
                          console.dir(d)
                          return d;
                        });
})

                    }}/>
                <button
                  type="submit"
                  className="btn btn-secondary chat-send w-md waves-effect waves-light"
                  onClick={() => {
                    inputFile.current.click();
                    console.log(inputValues);
                  }}
                >
                  <span className="d-none d-sm-inline-block me-2">Upload Icon</span>{" "}
                  <i className="mdi mdi-upload float-end"></i>
                </button>
                <button
                    style={{marginLeft: '10px'}}
                  type="submit"
                  className="btn btn-outline-danger chat-send w-md waves-effect waves-light"
                  onClick={() => {
                        setInputValues(() => {
                          let d = { ...inputValues };
                          d.icon = null;
                          console.dir(d)
                          return d;
                        });
                  }}
                >
                  <span className="d-none d-sm-inline-block me-2">Remove Icon</span>{" "}
                  <i className="mdi mdi-delete float-end"></i>
                </button>
                </React.Fragment>
              </Col>
              <div className="pt-3 chat-input-section">
                <Row>
                  <Col className="col-auto"></Col>
                </Row>
              </div>
            </React.Fragment>
            </SimpleBar>
          </div>
  
          <div className="p-3 chat-input-section">
            <Row>
              <Col className="col"></Col>
              <Col className="col-auto">
                <button
                  type="submit"
                  className="btn btn-primary chat-send w-md waves-effect waves-light"
                  onClick={() => {
                    saveDataIndex(inputValues, false).then((res) => {
                      props.onSave(res);
                    });
                    console.log(inputValues);
                  }}
                >
                  <span className="d-none d-sm-inline-block me-2">Save</span>{" "}
                  <i className="mdi mdi-content-save float-end"></i>
                </button>
              </Col>
            </Row>
          </div>
        </Card>
      </div>
    );
  
}

