import React, {useState, useEffect} from "react";

import FeatherIcon from "feather-icons-react";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import withRouter from "../../components/Common/withRouter";

//i18n
import {withTranslation} from "react-i18next";

// Redux Store
import {
    toggleLeftmenu,
} from "../../store/actions";

// Import Img
import logo from "../../assets/images/logo.png";

// Import Dropdown
import ProfileMenu from "../../components/Common/TopbarDropdown/ProfileMenu";
import NotificationDropdown from "../../components/Common/TopbarDropdown/HorizontalNotiDropdown";
import LanguageDropdown from "../../components/Common/TopbarDropdown/LanguageDropdown";
import SearchDropdown from "./SearchDropdown";

import {Collapse, Row, Col, DropdownToggle} from "reactstrap";

//redux
import {useDispatch} from "react-redux";
import {changeTheme} from '../../store/actions';
import {themeModeTypes} from '../../constants/layout';
import classname from "classnames";

import {getDataIndexes} from "../../Api";

const Navbar = (props) => {

    const dispatch = useDispatch();


    const [app, setapp] = useState(false);
    const [dataIndexes, setDataIndexes] = useState([]); // integer state


    useEffect(() => {
        var matchingMenuItem = null;
        var ul = document.getElementById("navigation");
        var items = ul.getElementsByTagName("a");
        removeActivation(items);
        for (var i = 0; i < items.length; ++i) {
            if (props.router.location.pathname === items[i].pathname) {
                matchingMenuItem = items[i];
                break;
            }
        }
        if (matchingMenuItem) {
            activateParentDropdown(matchingMenuItem);
        }
    });


    const removeActivation = items => {
        for (var i = 0; i < items.length; ++i) {
            var item = items[i];
            const parent = items[i].parentElement;
            if (item && item.classList.contains("active")) {
                item.classList.remove("active");
            }
            if (parent) {
                if (parent.classList.contains("active")) {
                    parent.classList.remove("active");
                }
            }
        }
    };

    function activateParentDropdown(item) {
        // item.classList.add("active");
        const parent = item.parentElement;
        if (parent) {
            parent.classList.add("active"); // li
            const parent2 = parent.parentElement;
            parent2.classList.add("active"); // li
            const parent3 = parent2.parentElement;
            if (parent3) {
                parent3.classList.add("active"); // li
                const parent4 = parent3.parentElement;
                if (parent4) {
                    parent4.classList.add("active"); // li
                    const parent5 = parent4.parentElement;
                    if (parent5) {
                        parent5.classList.add("active"); // li
                        const parent6 = parent5.parentElement;
                        if (parent6) {
                            parent6.classList.add("active"); // li
                        }
                    }
                }
            }
        }
        return false;
    }

    return (
        <React.Fragment>
            <header id="page-topbar" className="ishorizontal-topbar">
                <div className="navbar-header">
                    <div className="d-flex">
                        <div className="navbar-brand-box">
                            <Link to="/" className="logo logo-dark">
                <span className="logo-sm">
                  <img src={logo} alt="" height="30"/>
                </span>
                                <span className="logo-lg">
                  <img src={logo} alt="" height="45"/>{" "}
                </span>
                            </Link>

                            <Link to="/" className="logo logo-light">
                <span className="logo-sm">
                  <img src={logo} alt="" height="22"/>
                </span>
                                <span className="logo-lg">
                  <img src={logo} alt="" height="22"/>{" "}
                                    <span className="logo-txt">Symox</span>
                </span>
                            </Link>
                        </div>

                        <button
                            type="button"
                            className="btn btn-sm px-3 font-size-16 d-lg-none header-item"
                            data-bs-toggle="collapse"
                            data-bs-target="#topnav-menu-content"
                            onClick={() => {
                                props.toggleLeftmenu(!props.leftMenu);
                            }}
                        >
                            <i className="fa fa-fw fa-bars"></i>
                        </button>
                        <div className="topnav">
                            <nav
                                className="navbar navbar-light navbar-expand-lg topnav-menu"
                                id="navigation"
                            >
                                <Collapse
                                    isOpen={props.leftMenu}
                                    className="navbar-collapse"
                                    id="topnav-menu-content"
                                >
                                    <ul className="navbar-nav">




                                    </ul>
                                </Collapse>
                            </nav>
                        </div>
                    </div>
                    <div className="d-flex">


                                <Link
                                    style={{paddingTop: "21px"}}
          className="btn header-item noti-icon"
          tag="button"
          id="page-header-notifications-dropdown"
            to="/"
        >
          <FeatherIcon icon="home" className="icon-sm" />
        </Link>


                        <NotificationDropdown/>


                        <ProfileMenu/>
                    </div>
                </div>
            </header>
        </React.Fragment>
    );
};

const mapStatetoProps = (state) => {
    const {leftMenu} = state.Layout;
    return {leftMenu};
};

export default withRouter(
    connect(mapStatetoProps, {toggleLeftmenu})(withTranslation()(Navbar))
);
