import React, {useState, useRef, useEffect} from 'react';
import {
    Card,
    Col,
    Container,
    Row,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Button
} from "reactstrap";
import classnames from "classnames";

import {CardBody, Collapse, Tooltip} from 'reactstrap';
import {Link} from 'react-router-dom';
import {checkWordpressPlugin, deleteDataSource, saveDataSource} from '../../../Api';
import Select from "react-select";
import LinkButton from "./LinkButton";

function getEdit(dataSource, inputValues, setInputValues) {


    switch (dataSource.type) {


        case 'LinkDataSource':
            return (
                <LinkDataSource dataSource={dataSource} inputValues={inputValues} setInputValues={setInputValues}/>);
        case 'TextDataSource':
            return (
                <TextDataSource dataSource={dataSource} inputValues={inputValues} setInputValues={setInputValues}/>);
        case 'XMLDataSource':
            return (
                <XMLDataSource dataSource={dataSource} inputValues={inputValues} setInputValues={setInputValues}/>);
        case 'SQLDataSource':
            return (<SQLDataSource dataSource={dataSource} inputValues={inputValues} setInputValues={setInputValues}/>);
        case 'WordpressDataSource':
            return (<WordpressDataSourceEditor dataSource={dataSource} inputValues={inputValues}
                                               setInputValues={setInputValues}/>);
        case 'PdfDataSource':
            return (<PdfDataSourceEditor dataSource={dataSource} inputValues={inputValues}
                                         setInputValues={setInputValues}/>);
        case 'WebsiteDataSource':
            return (
                <WebsiteDataSource dataSource={dataSource} inputValues={inputValues} setInputValues={setInputValues}/>);
        case 'CSVDataSource':
            return 'fas fa-file-csv';
        default:
            return 'fas fa-question';


    }
}

function buildInput(name, inputValues, setInputValues) {
    let formattedName = name.replace("_", " ").charAt(0).toUpperCase() + name.slice(1);
    let placeholder = "Enter " + formattedName;
    return (
        <React.Fragment>
            <label className="form-label" htmlFor="formrow-firstname-input">{formattedName}</label>
            <input onInput={e => setInputValues(() => {
                let d = {...inputValues}
                d[name] = e.target.value;
                return d;
            })}
                   type="text" className="form-control mb-4" id="specificSizeInputGroupUsername"
                   placeholder={placeholder} value={inputValues[name]}/>
        </React.Fragment>
    )
}
const CheckboxInput =(props) => {
    let name = props.name;
    let inputValues = props.inputValues;
    let setInputValues = props.setInputValues;

    let formattedName = name.replace("_", " ").charAt(0).toUpperCase() + name.slice(1);
    let placeholder = "Enter " + formattedName;
      const [checked, setChecked] = useState(false);

    return (
        <React.Fragment>
            <label className="form-label" htmlFor="formrow-firstname-input">{formattedName}</label>
            <input
                checked={checked}
                onClick={e =>
                    setInputValues(() => {
                        let new_checked = !checked;
                        setChecked(new_checked)
                        let d = {...inputValues}
                        if(new_checked){
                            d[name] = e.target.value;
                        }
                        else{
                            d[name] = null;
                        }

                        return d;
                    })}
                   type="checkbox"
                className="form-check-input mb-4"

                id="specificSizeInputGroupUsername"
                   placeholder={placeholder} value={inputValues[name]}/>
        </React.Fragment>
    )
}

function buildTextArea(name, inputValues, setInputValues) {
    let formattedName = name.replace("_", " ").charAt(0).toUpperCase() + name.slice(1);
    let placeholder = "Enter " + formattedName;

    return (
        <React.Fragment>
            <label className="form-label" htmlFor="formrow-firstname-input">{formattedName}</label>
            <textarea onInput={e => setInputValues(() => {
                let d = {...inputValues}
                d[name] = e.target.value;
                return d;
            })}
                      type="text" rows="5" className="form-control mb-4" id="specificSizeInputGroupUsername"
                      placeholder={placeholder} value={inputValues[name]}/>

        </React.Fragment>
    )
}

export default function DataSourceEditor(props) {


    let [dataPages, setDataPages] = useState([]);

    let [step, setStep] = useState(0);


    let [inputValues, setInputValues] = useState(props.dataSource);
    if (props.dataSource.id != inputValues.id) {
        setInputValues(props.dataSource);
    }
    let editor = null;
    editor = getEdit(inputValues, inputValues, setInputValues);

    let dropDownTypes = [];

    let textDataSourceSelect  ={
        "onClick": () => {
            setInputValues({...inputValues, type: "TextDataSource"})
        },
        "value": "TextDataSource",
        "label": "Text"
    }

    dropDownTypes.push(textDataSourceSelect);

    dropDownTypes.push({
        "onClick": () => {
            setInputValues({...inputValues, type: "WebsiteDataSource"})
        },
        "value": "WebsiteDataSource",
        "label": "Website"
    });
    dropDownTypes.push({
        "onClick": () => {
            setInputValues({...inputValues, type: "WordpressDataSource"})
        },
        "value": "WordpressDataSource",
        "label": "Wordpress"
    });
    dropDownTypes.push({
        "onClick": () => {
            setInputValues({...inputValues, type: "CSVDataSource"})
        },
        "value": "CSVDataSource",
        "label": "CSV"
    });
    dropDownTypes.push({
        "onClick": () => {
            setInputValues({...inputValues, type: "PdfDataSource"})
        },
        "value": "PdfDataSource",
        "label": "PDF"
    });
    dropDownTypes.push({
        "onClick": () => {
            setInputValues({...inputValues, type: "JSONDataSource"})
        },
        "value": "JSONDataSource",
        "label": "JSON"
    });
    dropDownTypes.push({
        "onClick": () => {
            setInputValues({...inputValues, type: "XMLDataSource"})
        },
        "value": "XMLDataSource",
        "label": "XML"
    });


    if (inputValues.id === "new") {
        return (
            <React.Fragment>
                {step === 0 ? (
                    <Col className="col-12">

                        {buildInput("name", inputValues, setInputValues)}

                        <React.Fragment>
                            <label className="form-label" htmlFor="formrow-firstname-input">Type</label>
                            <Select
                                className="mb-2"
                                defaultValue={textDataSourceSelect}
                                onChange={(e) => {

                                    e.onClick();

                                }}
                                options={dropDownTypes}
                            >


                            </Select>
                        </React.Fragment>
                        <br></br>
                        <button type="submit"
                                className="btn btn-primary chat-send w-md waves-effect waves-light"
                                onClick={() => {
                                    setStep(1);
                                }}
                        ><span className="d-none d-sm-inline-block me-2">Next</span> <i
                            className="mdi mdi-arrow-right float-end"></i></button>

                    </Col>) : step === 1 ? (
                    <React.Fragment>
                        {editor}
                        <div className="pt-3 chat-input-section">
                            <Row>

                                <Col className="col-auto">
                                    <button type="submit"
                                            className="btn btn-outline-warning chat-send w-md waves-effect waves-light"
                                            onClick={() => {
                                                setStep(0);
                                            }}
                                    ><i className="mdi mdi-arrow-left float-start"></i><span
                                        className="d-none d-sm-inline-block me-2">Back</span></button>
                                    <button
                                        style={{marginLeft: "10px"}}
                                        type="submit"
                                        className="btn btn-primary chat-send w-md waves-effect waves-light"
                                        onClick={() => {
                                            saveDataSource(inputValues, false).then((res) => {
                                                props.onSave(res);
                                            });
                                        }}
                                    ><span className="d-none d-sm-inline-block me-2">Save</span> <i
                                        className="mdi mdi-content-save float-end"></i></button>
                                </Col>

                            </Row>
                        </div>
                    </React.Fragment>
                ) : ""
                }


            </React.Fragment>
        );
    }

    return (
        <React.Fragment>

            <Col className="col-12">

                {buildInput("name", inputValues, setInputValues)}

            </Col>
            {editor}


            <Col className="col-12" style={{display: "none"}}>
                <label className="form-label" htmlFor="formrow-firstname-input">API Key</label>

                <div className="input-group">
                    <button className="btn input-group-text">COPY</button>
                    <input readOnly={true} type="text" className="form-control" id="specificSizeInputGroupUsername"
                           placeholder="Api-Key" value={inputValues.api_key}/>
                </div>
            </Col>
            <div className="pt-3 chat-input-section">
                <Row>


                    <Col className="col-auto">
                        <button type="submit"
                                className="btn btn-primary chat-send w-md waves-effect waves-light"
                                onClick={() => {
                                    saveDataSource(inputValues, true).then((res) => {
                                        props.onSave(res);
                                    });
                                    console.log(inputValues);
                                }}
                        ><span className="d-none d-sm-inline-block me-2">Save Data Source</span> <i
                            className="mdi mdi-content-save float-end"></i></button>
                    </Col>
                    <Col className="col-auto">
                        <button type="submit"
                                className="btn btn-danger chat-send w-md waves-effect waves-light"
                                onClick={() => {
                                    deleteDataSource(inputValues.id).then((res) => {
                                        props.onSave(res);
                                    });
                                    console.log(inputValues);
                                }}
                        ><span className="d-none d-sm-inline-block me-2">Delete Data Source</span> <i
                            className="mdi mdi-trash-can-outline float-end"></i></button>
                    </Col>
                </Row>
            </div>
        </React.Fragment>
    );

}

function PagesCollapse(props) {
    const [collapsePages, setcollapsePages] = useState(false);
    const children = (

        <Card>
            <LinkButton to="#checkout-shippinginfo-collapse"
                  className={classnames(
                      "text-dark collapsed",
                      {collapsed: !collapsePages}
                  )}
                  type="button"
                  onClick={() => {
                      setcollapsePages(!collapsePages)
                  }}
                  style={{cursor: "pointer"}}>
                <div className="p-4">

                    <div className="d-flex align-items-center">
                        <div className="flex-shrink-0 me-3">
                            <i className="fas fa-globe text-primary "></i>
                        </div>
                        <div className="flex-grow-1 overflow-hidden">
                            <Row>
                                <Col lg={3} sm={4}><span>{props.dataSource.status}</span></Col>
                                <Col lg={3} sm={4}></Col>
                                <Col lg={3} sm={4}></Col>
                            </Row>
                        </div>
                        <div className="flex-shrink-0">
                            <i className="mdi mdi-chevron-up accor-down-icon font-size-24"></i>
                        </div>
                    </div>
                </div>
            </LinkButton>
            <Collapse
                isOpen={collapsePages}
                id="checkout-shippinginfo-collapse"
            >


                <div className="p-4 border-top">


                    <Row>
                        <Col lg={12} sm={12}>
                            <Row>
                                <Col lg={8} sm={12}>
                                    <span className="fs-14 mb-4 d-block">URL</span>
                                </Col>
                                <Col lg={4} sm={12}>
                                    <span className="fs-14 mb-4 d-block">Status Code</span>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    {
                        props.dataSource.pages ? props.dataSource.pages.map((item, key) => {
                                return (
                                    <Row>
                                        <Col lg={12} sm={12}>
                                            <div className="mb-3 mb-md-0">
                                                <label className="card-radio-label mb-0">
                                    <span className="card-radio text-truncate p-3">
                                        <Row>
                                            <Col lg={8} sm={12}>
                                            <Link className="fs-14 mb-4 d-block" to={item.url}
                                                  target={"_blank"}>{item.url}</Link>

                                            </Col>
                                            <Col lg={4} sm={12}>
                                            <span className="fs-14 mb-4 d-block">{item.status_code}</span>

                                            </Col>
                                        </Row>
                                    </span>
                                                </label>


                                            </div>
                                        </Col>
                                    </Row>
                                )
                            })
                            : ""
                    }
                </div>
            </Collapse>
        </Card>

    );
    return children;
}

function WebsiteDataSource(props) {
    const [checked, setChecked] = useState(props.inputValues.limit ? true : false);

    const children = PagesCollapse(props)
    return (
        <Col className='col-12'>
            {buildInput("url", props.inputValues, props.setInputValues)}
            <label className="form-label" htmlFor="formrow-firstname-input">Limit To Root Url</label>
            <br></br>

            <input
                checked={checked}
                onClick={e =>
                    props.setInputValues(() => {
                        let new_checked = !checked;
                        setChecked(new_checked)
                        let d = {...props.inputValues}
                        if(new_checked){
                            d['limit'] = e.target.value;
                        }
                        else{
                            d['limit'] = null;
                        }

                        return d;
                    })}
                   type="checkbox"
                className="form-check-input mb-4"

                id="specificSizeInputGroupUsername"
                 value={1}/>

            {
                props.inputValues.id !== "new" ? (
                  <React.Fragment>
                    <hr></hr>
                    <label>Pages</label>
                    {children}
                  </React.Fragment>
                ):("")
            }

        </Col>
    )
}

function WordpressDataSourceEditor(props) {
    const children = PagesCollapse(props)
    const [categories, setCategories] = useState([]);
    const renderCategories = () => {
        return categories.map((item, key) => {
            return (
                <li>
                    <input type="checkbox" checked={item.checked}
                           onClick={(e) => {
                                let d = [...categories];
                                d[key].checked = ! d[key].checked;

                                   props.setInputValues(() => {
                                       let data = {...props.inputValues};
                                       data.categories = Object.values(d);
                                       console.dir(d)
                                       return data;
                                   });
                            }}>
                    </input>
                    <span style={{'marginLeft': "5px"}}>{item.name}</span>
                </li>
            )
        })

    }
    return (
        <Col className='col-12'>
            {buildInput("url", props.inputValues, props.setInputValues)}

            <Link to="/media/plugin/ai_link.zip" target="_blank" download className='btn btn-sm btn-secondary'>Download Plugin</Link>


            <Button size={"sm"} style={{marginLeft: "15px"}} onClick={() => {
                checkWordpressPlugin(props.inputValues.url).then((res) => {
                    setCategories(res.categories)
                    console.log(res)
                }).catch((err) => {
                    console.log(err)
                })
            }}>Check Plugin</Button>

            {
                categories && categories.length > 0 ? (
                    <React.Fragment>
                        <br></br>

                        <label className={"mt-2"}>Categories</label>
                        <ul style={{"listStyleType": "none"}}>
                            {renderCategories()}
                        </ul>
                    </React.Fragment>
                ) : ("")
            }

            {
                props.inputValues.id !== "new" ? (
                  <React.Fragment>
                    <hr></hr>
                    <label>Pages</label>
                    {children}
                  </React.Fragment>
                ):("")
            }
        </Col>
    )
}

function LinkDataSource(props) {
    return (
        <Col className='col-12'>
            {buildInput("url", props.inputValues, props.setInputValues)}
            {buildInput("username", props.inputValues, props.setInputValues)}
            {buildInput("password", props.inputValues, props.setInputValues)}
        </Col>
    )
}

function TextDataSource(props) {
    return (
        <Col className='col-12'>
            {buildTextArea("text", props.inputValues, props.setInputValues)}
        </Col>
    )
}
function XMLDataSource(props) {

    const [allNodes, setAllNodes] = useState([]);
    const [ignoredElements, setIgnoredElements] = useState([]);

    useEffect(() => {
         let nns = getNodeNames(props.inputValues.text);
         setAllNodes(nns);

    }, [props.inputValues.text]);

    useEffect(() => {
          setIgnoredElements(props.inputValues.ignored_elements ? props.inputValues.ignored_elements.split(',') : []);
    }, [props.inputValues.ignored_elements]);

function getNodeNames(xmlString) {
    if(! xmlString){
        return [];
    }
  // Parse the XML string into a DOM object
  const parser = new DOMParser();
  const xmlDoc = parser.parseFromString(xmlString, "text/xml");

  // Function to traverse the XML nodes recursively
  function traverseNodes(node, names) {
    // If the node is an element, add its name to the array
    if (node.nodeType === Node.ELEMENT_NODE) {
      names.add(node.nodeName);
    }
    // Traverse the node's children if it has any
    node.childNodes.forEach(child => traverseNodes(child, names));
  }

  // Use a Set to avoid duplicate names
  const nodeNames = new Set();

  // Start traversing from the document element
  traverseNodes(xmlDoc.documentElement, nodeNames);

  // Convert the set to an array and return it
  return Array.from(nodeNames);
}
    const onChange = (e) => {


         let nns = getNodeNames(e.target.value);

         if(nns.length > 0) {

                 let d = {...props.inputValues};
                d['text'] = e.target.value;

                setAllNodes(nns);
                props.setInputValues(d);

         }

    }

    function getIgnoredElements(){
        return allNodes.map((item, key) => {
            return (
                <li>
                    <input type="checkbox" checked={ignoredElements.includes(item)}
                           onClick={(e) => {
                               let d = [...ignoredElements];
                               if(d.includes(item)){
                                   d.splice(d.indexOf(item), 1);
                               }
                               else{
                                   d.push(item);
                               }

                               props.setInputValues(() => {
                                   let data = {...props.inputValues};
                                   data.ignored_elements = d.join(',');
                                   return data;
                               });
                           }}>
                    </input>
                    <span style={{'marginLeft': "5px"}}>{item}</span>
                </li>
            )
        })
    }

    return (
        <Col className='col-12'>

        <React.Fragment>
            <label className="form-label" htmlFor="formrow-firstname-input">XML Summary - <i style={{color: "gray", fontSize: "12px"}}>Enter a summary of your data to help your bot understand how to interpret it</i></label>
            <textarea onInput={e => {
                props.setInputValues(() => {
                    let d = {...props.inputValues}
                    d['summary'] = e.target.value;
                    return d;

                })}}
                      type="text" rows="5" className="form-control mb-4" id="specificSizeInputGroupUsername"
                      placeholder={'Enter a summary of your data to help your bot understand how to interpret it'} value={props.inputValues['summary']}/>

        </React.Fragment>
        <React.Fragment>
            <label className="form-label" htmlFor="formrow-firstname-input">XML Data</label>
            <textarea onInput={e => onChange(e)}
                      type="text" rows="5" className="form-control mb-4" id="specificSizeInputGroupUsername"
                      placeholder={'XML Input'} value={props.inputValues['text']}/>

        </React.Fragment>
            <label className="form-label" htmlFor="formrow-firstname-input">Ignored Elements</label>
            <ul>
                {getIgnoredElements()}
            </ul>
        </Col>
    )
}

function PdfDataSourceEditor(props) {


    const inputFile = useRef(null)

    const convertBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file)
            fileReader.onload = () => {
                resolve(fileReader.result);
            }
            fileReader.onerror = (error) => {
                reject(error);
            }
        })
    }


    return (
        <Col className='col-12'>
            {props.inputValues.id === "new" ?
                (
                    <React.Fragment><i style={{color: "gray", fontSize: "12px"}}>Select your PDF files to upload.</i>
                        <br></br>
                        <input type='file' id='file' ref={inputFile}
                               onChange={(e) => {

                                   convertBase64(e.target.files[0]).then((res) => {
                                       props.setInputValues(() => {
                                           let d = {...props.inputValues};
                                           d.pdf_files = [res];
                                           console.dir(d)
                                           return d;
                                       });
                                   })

                               }}/>
                    </React.Fragment>
                ) : (props.inputValues.file_urls.map((item, key) => {
                    return (<a target={"_blank"} href={item}>View File</a>)
                }))
            }

        </Col>
    )
}

function SQLDataSource(props) {
    return (
        <Col className='col-12'>
            {buildInput("host", props.inputValues, props.setInputValues)}
            {buildInput("database", props.inputValues, props.setInputValues)}
            {buildInput("username", props.inputValues, props.setInputValues)}
            {buildInput("password", props.inputValues, props.setInputValues)}
        </Col>
    )
}