import React from 'react';
import {Link, useNavigate} from 'react-router-dom';
import { Col, Container, Row, Card, CardBody } from 'reactstrap';

import logosm from "../../assets/images/logo.png";
import AuthCode from './AuthCode';
import {checkResetPasswordSession, createResetPasswordSession, submitResetPasswordSession} from "../../Api";

const ForgotPassword = () => {
    document.title = "AiBizChats.com | Recover Password";
    const [codeSent, setCodeSent] = React.useState(false); //step 1 send code
    const [codeCorrect, setCodeCorrect] = React.useState(false); //step 2 check code
    const [token, setToken] = React.useState(null); //step 3 save token to be used for final request

    const [email, setEmail] = React.useState(null);
    const [code, setCode] = React.useState(null);
    const [password, setPassword] = React.useState(null);

            let navigate = useNavigate();

    let page = null;
    if(codeCorrect){
       page = (

                                    <React.Fragment>
              <div className="text-center mt-2">
                  <h5 className="text-primary">Enter</h5>
              </div>
              <div className="p-2 mt-4">
                  <div className="alert alert-success text-center small mb-4" role="alert">
                    Code Validated: Please enter your new password
                  </div>


                      <div className="mb-3 text-center">
                          <label className="form-label" htmlFor="useremail"> New Password</label>

                            <input className={'form-control'} type={"text"} onChange={(e)=> {
                                setPassword(e.target.value);
                            }} />
                      </div>

                      <div className="mt-3 text-center">
                          <button className="btn btn-primary w-sm waves-effect waves-light" type="submit" onClick={()=>{
                            submitResetPasswordSession(token, email, code, password).then((res)=> {
                                if(res.status === 'success'){
                                    navigate('/login')
                                }
                            })
                          }}>Update</button>
                      </div>


              </div>
                                    </React.Fragment>

       )
    }
    else if(codeSent){
      page = (

                                    <React.Fragment>
                                      <div className="text-center mt-2">
                                          <h5 className="text-primary">Enter</h5>
                                      </div>
                                      <div className="p-2 mt-4">
                                          <div className="alert alert-success text-center small mb-4" role="alert">
                                                Check your email and enter the code sent to you!
                                          </div>


                                              <div className="mb-3 text-center">
                                                    <AuthCode
                                                        length={7}
                                                        allowedCharacters='numeric'
                                                        inputClassName=" authcode-input"
                                                        onChange={(code)=>{
                                                            setCode(code);
                                                        }}
                                                    />
                                              </div>

                                              <div className="mt-3 text-center">
                                                  <button className="btn btn-primary w-sm waves-effect waves-light" type="submit" onClick={()=>{
                                                    checkResetPasswordSession(email, code).then((res)=> {
                                                        if(res.status === 'success'){
                                                            setCodeCorrect(true);
                                                            setToken(res.token);
                                                        }
                                                    })
                                                  }}>Check</button>
                                              </div>


                                      </div>
                                    </React.Fragment>

      )
    }
    else{
        page = (
                                    <React.Fragment>
                                        <div className="text-center mt-2">
                                            <h5 className="text-primary">Reset Password</h5>
                                        </div>
                                        <div className="p-2 mt-4">
                                            <div className="alert alert-success text-center small mb-4" role="alert">
                                                Enter your Email and instructions will be sent to you!
                                            </div>


                                                <div className="mb-3">
                                                    <label className="form-label" htmlFor="useremail">Email</label>
                                                    <input type="email" className="form-control" id="useremail" placeholder="Enter email" onChange={(e)=>{
                                                        setEmail(e.target.value);
                                                    }} />
                                                </div>

                                                <div className="mt-3 text-end">
                                                    <button className="btn btn-primary w-sm waves-effect waves-light" type="submit" onClick={()=>[
                                                        createResetPasswordSession(email).then((res)=> {
                                                            console.log(res);
                                                            setCodeSent(true);

                                                        }, (err)=> {

                                                        })
                                                    ]}>Reset</button>
                                                </div>

                                                <div className="mt-4 text-center">
                                                    <p className="mb-0">Remember It? <Link to="/login" className="fw-medium text-primary"> Sign in </Link></p>
                                                </div>

                                        </div>
                                   </React.Fragment>
        )
    }

    return (
        <React.Fragment>
            <div className="authentication-bg min-vh-100">
                <div className="bg-overlay"></div>
                <Container>
                    <div className="d-flex flex-column min-vh-100 px-3 pt-4">
                        <Row className="justify-content-center my-auto">
                            <Col md={8} lg={6} xl={5}>


                                <Card>
                                           <CardBody className="p-4">
                                <div className="text-center mb-4">
                                    <Link to="/">
                                        <img src={logosm} alt="" height="50" />
                                    </Link>
                                </div>
                                    {page}
                                           </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={12}>
                                <div className="text-center text-muted p-4">
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default ForgotPassword;