import {Card, Col, Row} from "reactstrap";
import {apiRoot, askBot, generateMemoryKey} from "../../Api";
import {Link} from "react-router-dom";
import SimpleBar from "simplebar-react";
import React, {useEffect, useId, useRef, useState} from "react";
import ChatBubble from "./components/ChatBubble";


const Chat = (props) => {
    const [memoryKey, setMemoryKey] = useState(props.memoryKey);
    const dataIndex = props.dataIndex;
    const isPublic = props.isPublic || false;
    const [messages, setMessages] = useState([]);
    const [input, setInput] = useState("");
    const id = useId();
    const messagesEndRef = useRef(null)

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({behavior: "smooth", block: "end", })
    }

    useEffect(() => {
        scrollToBottom()
    }, [messages]);
    useEffect(() => {
        if (dataIndex) {
            setMessages([{
                isBot: true,
                text: dataIndex.greeting
            }]);
        }
        // checkLoaders();

    }, [dataIndex]);

    useEffect(() => {

            if(! memoryKey){
                generateMemoryKey(dataIndex.id).then((res) => {
                    setMemoryKey(res.memory_key)
                });
            }
        }, []
    )


    const sendMessage = () => {
        if (!input) return;

        setMessages(messages => [...messages, {isBot: false, text: input}]);

        askBot(dataIndex.id, memoryKey, input).then((res) => {

            setMessages(messages => [...messages, {isBot: true, text: res.message}]);


        });

        setInput('');
    }
    let classNames = 'w-100 user-chat  ms-lg-3 align-middle'
    let styles = {}
    if (isPublic) {
        classNames = 'w-100 public-chat'
        styles = {
            height: "100%",
        }
    }
    return (
        <div className={classNames} style={styles}>
            <Card className={"chatCard h-100"} style={{justifyContent: "space-between"}}>
                <div className="p-3 px-lg-4 border-bottom" id={'chat-message-header'}>
                    <Row>
                        <Col md={12} style={{marginRight: "", display: "inline"}} className="1">
                            <div style={{float: "left"}}>
                                {
                                    dataIndex.icon ? (
                                        <img
                                            src={apiRoot + dataIndex.icon}
                                            alt=""
                                            className="avatar-md rounded-circle img-thumbnail"
                                        />
                                    ) : (
                                        <div className="avatar-md">
                                            <div
                                                className="avatar-title bg-soft-primary text-primary h1 m-0 rounded-circle">
                                                <i className="bx bxs-user-circle"></i>
                                            </div>
                                        </div>
                                    )
                                }

                            </div>
                            <div className="align-middle"
                                 style={{height: "100%", "marginTop": "8px", paddingLeft: "75px"}}>
                                <h5 className="font-size-16 mb-1 text-truncate">
                                    <Link to="#"
                                          className="text-dark">{dataIndex ? dataIndex.public_name : ""}</Link>
                                </h5>
                                {isPublic ? "" :<p className="text-muted text-truncate mb-0">{dataIndex.data_sources.length} Data
                                    Sources ({dataIndex.size})</p>}

                            </div>


                        </Col>

                    </Row>
                </div>

                <div className="px-lg-1" id={"chat-message-wrapper"} >

                    <SimpleBar className="chat-conversation p-3" style={{height: "100%"}}
                               data-simplebar>
                        {memoryKey ?
                            <React.Fragment>

                                <ul className="list-unstyled mb-0">
                                    {messages.map((message) => {
                                        return (<ChatBubble
                                                key={message.text}
                                                isBot={message.isBot}
                                                text={message.text}
                                                botName={dataIndex.public_name}
                                            />

                                        )
                                    })}

                                </ul>
<div ref={messagesEndRef}/>

                            </React.Fragment>
                            :
                            <div>

                                <h3>Loading Chat...</h3>
                                <div
                                    className="spinner-border text-primary m-1"
                                    role="status">
                                                                                <span
                                                                                    className="sr-only">Loading...</span>
                                </div>
                            </div>


                        }
                    </SimpleBar>
                </div>

                <div className="p-3 chat-input-section">
                    <Row>
                        <Col className="col">
                            <div className="position-relative">
                                <input type="text"
                                       className="form-control chat-input rounded"
                                       placeholder="Enter Message..."

                                       id={id} value={input}
                                       onKeyDown={e => {
                                           if (e.key === 'Enter') {
                                               sendMessage();
                                           }
                                       }}
                                       onInput={e => setInput(e.target.value)}
                                />
                            </div>
                        </Col>
                        <Col className="col-auto">
                            <button type="submit"
                                    className="btn btn-primary chat-send w-md waves-effect waves-light"
                                    onClick={sendMessage}
                                    disabled={memoryKey ? false : true}
                            ><span
                                className="d-none d-sm-inline-block me-2">Send</span>
                                <i className="mdi mdi-send float-end"></i>
                            </button>
                        </Col>
                    </Row>
                </div>
            </Card>
        </div>
    )

}

export default Chat;

