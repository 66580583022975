import {getLoggedinUser} from "./helpers/api_helper.js";

let apiUrl = "/api";

let apiRoot = "https://aibizchats.com";

if (window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1")
{
  apiRoot = "http://localhost:8000";
}

apiUrl = apiRoot + "/api";

export {apiRoot};





function fetchAuthorized(url, options) {

  let user= getLoggedinUser();
  let token = user.token;

  if (!options) {
    options = {};
  }
  if (!options.headers) {
    options.headers = {};
  }
  options.headers["Authorization"] = "Token " + token;
  return fetch(url, options);
}

export async function login(username, password) {
  const response = await fetch(apiUrl + "/login", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ username, password }),
  });
  return await response.json();
}

export async function createResetPasswordSession(email){
    const response = await fetch(apiUrl + "/password_reset/create/", {
        method: "POST",
        headers: {
        "Content-Type": "application/json",
        },
        body: JSON.stringify({ "email": email }),
    });
    return await response.json();
}

export async function checkResetPasswordSession(email, code){
    const response = await fetch(apiUrl + "/password_reset/check/", {
        method: "POST",
        headers: {
        "Content-Type": "application/json",
        },
        body: JSON.stringify({ "code": code, "email": email }),
    });
    return await response.json();
}
export async function submitResetPasswordSession(token, email, code, password){
    const response = await fetch(apiUrl + "/password_reset/submit/", {
        method: "POST",
        headers: {
        "Content-Type": "application/json",
        },
        body: JSON.stringify({ "code": code, "email": email, 'token': token, 'password': password }),
    });
    return await response.json();
}

export async function getDataIndexes() {
  const response = await fetchAuthorized(apiUrl + "/data_indexes/");
  return await response.json();
}

export async function getDataIndex(id) {
    const response = await fetchAuthorized(apiUrl + "/data_indexes/" + id);
    return await response.json();
}

export async function getDataIndexForEmbed(chat_token) {
    const response = await fetch(apiUrl + "/data_indexes_embed/" + chat_token);
    return await response.json();
}

export async function deleteDataIndex(id) {
    const response = await fetchAuthorized(apiUrl + "/data_indexes/" + id, {method: 'DELETE',});
    let res = await response.json();
    return res;
}
export async function deleteDataSource(id) {
    const response = await fetchAuthorized(apiUrl + "/delete_data_source/", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({'id': id}),
      });
    let res = await response.json();
    return res;
}

export async function getDataSourcesLoadingProgress(index_id){
    const response = await fetchAuthorized(apiUrl + "/data_indexes/" + index_id + "/loading_progress");
    return await response.json();
}

export async function getDataIndexDataSources(index_id) {
  const response = await fetchAuthorized(
    apiUrl + "/data_indexes/" + index_id + "/data_sources"
  );
  return await response.json();
}

export async function getUserSettings() {
    const response = await fetchAuthorized(apiUrl + "/user_settings/");
    return await response.json();
}

export async function saveUserSettings(userSettings) {
  const response = await fetchAuthorized(apiUrl + "/user_settings/", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(userSettings),
  });
  return await response.json();
}
export async function saveDataSource(data_source_object, reload = false) {
  if (reload) {
    data_source_object["reload"] = true;
  }
  const response = await fetchAuthorized(apiUrl + "/save_data_source/", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data_source_object),
  });
  return await response.json();
}
export async function checkWordpressPlugin(url) {
  const response = await fetchAuthorized(apiUrl + "/check_wordpress_plugin/", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({url: url}),
  });
  return await response.json();
}
export async function askBot(index_id, memory_key, message) {
    const fetchFunction = getLoggedinUser() ? fetchAuthorized : fetch;
  const rawResponse = await fetchFunction(
    apiUrl + "/data_indexes/" + index_id + "/ask_bot",
    {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ message: message, memory_key: memory_key }),
    }
  );
  return await rawResponse.json();
}

export async function newDataIndex(data=null) {
  const rawResponse = await fetchAuthorized(
    apiUrl + "/data_indexes/new/",
    {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }
  );
  return await rawResponse.json();
}

export async function generateMemoryKey(index_id) {
  const rawResponse = await fetchAuthorized(
    apiUrl + "/data_indexes/" + index_id + "/generate_memory_key"
  );
  return await rawResponse.json();
}

export async function getAuthToken(username, password) {
  const rawResponse = await fetch(apiUrl + "/obtain_auth_token/", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      username: username,
      password: password,
    }),
  });
  let res = await rawResponse.json();
  if (res.token){
    localStorage.setItem("authUser", JSON.stringify(res));
  }
  return res;
}

export async function saveDataIndex(data_index_object) {
  const response = await fetchAuthorized(apiUrl + "/save_data_index/", {
    method: "POST",
                    processData: false,
                contentType: false,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data_index_object),
  });
  return await response.json();
}