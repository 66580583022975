import { Card, Col, Container, Row, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import { Link } from "react-router-dom";

export default function ChatBubble(props) {
  let text = props.text;
  let isBot = props.isBot;
  let botName = props.botName;
    let className = "";
    let person = "";
    if (isBot) {
        className = "conversation-list";
        person = botName;
    } else {
        className = "conversation-list right";
        person = "You";
    }
  return (
    <li className={className}>
      <div className="conversation-list">
        <div className="ctext-wrap">
          <div className="ctext-wrap-content">
            <h5 className="font-size-14 conversation-name">
              <Link to="#" className="text-dark">
                {person}
              </Link>
              <span className="d-inline-block font-size-12 text-muted time">
               
              </span>
            </h5>
            <p className="mb-0">{text}</p>
          </div>

        </div>
      </div>
    </li>
  );
}
