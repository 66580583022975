

import React, { useState, useRef, useEffect } from 'react';
import Select from "react-select";

import { useLocation } from 'react-router-dom';

import { Card,  Col, Container, Row, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";

import {apiRoot, saveDataIndex} from '../../../Api';

import SimpleBar from "simplebar-react";
export default function EmbedEditor  (props) {
    let [inputValues, setInputValues] = useState(props.dataIndex);
    let [iconImage, setIconImage] = useState(null);
    useEffect(() => {
      setInputValues(props.dataIndex);
    }, []);
      const inputFile = useRef(null)

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file)
      fileReader.onload = () => {
        resolve(fileReader.result);
      }
      fileReader.onerror = (error) => {
        reject(error);
      }
    })
  }

  const getShareLink = (dataIndex) => {
            let t = window.location.origin + "/chat/";
            let code = `${t}${dataIndex.id}/`;
            return code;
  }
  const getEmbedCode = (dataIndex, embed='') => {
          let t = window.location.origin + "/chat.js";
          let preEmbed = '';
          if(embed){
              preEmbed = '<div class="aibizchats-iframe-wrapper"></div>';
              embed = "\nembed: true"
          }
          let code = `${preEmbed}<script src="${t}"></script>
<script>
    var chatConfig = {
    token: ${dataIndex.id},${embed}
    };
    initializeChatWidget(chatConfig);
</script>`
return code;
  }


    const inputLabel = (label, helper) => {
    return (
                            <label
                      className="form-label"
                      htmlFor="formrow-firstname-input"
                    >
                                {label} - <i style={{color: "gray", fontSize: "12px"}}>{helper}</i>
                    </label>
    )
  }
    return (
      <div className="w-100 user-chat mt-1 mt-sm-0 ms-lg-3">
        <Card>
          <div className="p-3 px-lg-4 border-bottom">
            <Row>
              <Col md={4} className="col-6"></Col>
              <Col  className="col-12">
                <h4 className=''>Copy Embed</h4>
              </Col>
            </Row>
          </div>
  
          <div className="px-lg-2">
            <SimpleBar className="chat-conversation p-3" data-simplebar>
              <React.Fragment>


      
              <Col className="col-12">
                <React.Fragment>
                    {inputLabel("Share With Others", "Share this link with others so they can access your chatbot.")}
                    <input
                      readOnly={true}
                      type="text"
                      rows="5"
                      className="form-control mb-4"
                      id="specificSizeInputGroupUsername"
                      value={getShareLink(inputValues)}
                    />

                    {inputLabel("Chatbot Privacy", "Set this to 'Private' to restrict access to the share link above.")}

                    <label
                      className="form-label"
                      htmlFor="formrow-firstname-input"
                    >
                    </label>
                    <Select
                        className={"mb-4"}
                        defaultValue={{"value": inputValues.private, "label": inputValues.private ? "Private" : "Public"}}
                        onChange={(e) => {
                          setInputValues(() => {
                            let d = { ...inputValues };
                            d.private = e.value;
                            return d;
                          });
                        }}
                        options={[
                            { "value": true, "label": "Private" },
                            { "value": false, "label": "Public" },
                        ]}
                    >
                    </Select>
                    {inputLabel("Copy Button Embed", "Copy this embed code into a website to make the chatbot appear there.")}


                  <textarea
                      readOnly={true}
                      type="text"
                      rows="8"
                      className="form-control mb-4"
                      value={getEmbedCode(inputValues)}
                  />

                    {inputLabel("Copy Direct Embed", "Copy this div to your website anywhere you would like the chatbot to appear.")}

                  <textarea
                      readOnly={true}
                      type="text"
                      rows="8"
                      className="form-control mb-4"
                      value={getEmbedCode(inputValues, true)}
                  />
                </React.Fragment>
              </Col>
              <div className="pt-3 chat-input-section">
                <Row>
                  <Col className="col-auto"></Col>
                </Row>
              </div>
            </React.Fragment>
            </SimpleBar>
          </div>
  
          <div className="p-3 chat-input-section">
            <Row>
              <Col className="col"></Col>
              <Col className="col-auto">
                <button
                  type="submit"
                  className="btn btn-primary chat-send w-md waves-effect waves-light"
                  onClick={() => {
                    saveDataIndex(inputValues, false).then((res) => {
                      props.onSave(res);
                    });
                    console.log(inputValues);
                  }}
                >
                  <span className="d-none d-sm-inline-block me-2">Save</span>{" "}
                  <i className="mdi mdi-content-save float-end"></i>
                </button>
              </Col>
            </Row>
          </div>
        </Card>
      </div>
    );

}

