
import {getLoggedinUser} from '../../../helpers/api_helper.js';

import React, { useState, useRef, useEffect } from 'react';
import Select from "react-select";

import { useLocation } from 'react-router-dom';

import { Card,  Col, Container, Row, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";

import {apiRoot, getUserSettings, saveDataIndex, saveUserSettings} from '../../../Api';

import SimpleBar from "simplebar-react";
export default function OpenAiAccountEditor  (props) {
    let [inputValues, setInputValues] = useState({});
    let [iconImage, setIconImage] = useState(null);

    useEffect(() => {

        let user = getLoggedinUser();
        getUserSettings().then((res) => {
                    setInputValues(res.data);

        });
    }, []);


    const inputLabel = (label, helper) => {
    return (
                            <label
                      className="form-label"
                      htmlFor="formrow-firstname-input"
                    >
                                {label} - <i style={{color: "gray", fontSize: "12px"}}>{helper}</i>
                    </label>
    )
  }


    return (
      <div className="w-100 user-chat mt-1 mt-sm-0 ms-lg-3">
        <Card>
          <div className="p-3 px-lg-4 border-bottom">
            <Row>
              <Col md={4} className="col-6"></Col>
              <Col  className="col-12">
                <h4 className=''>Your OpenAI Account</h4>
              </Col>
            </Row>
          </div>
  
          <div className="px-lg-2">
            <SimpleBar className="chat-conversation p-3" data-simplebar>
              <React.Fragment>


      
              <Col className="col-12">




                                              <label
                      className="form-label"
                      htmlFor="formrow-firstname-input"
                    >
                                Open AI Key - <i style={{color: "gray", fontSize: "12px"}}>
                                                    An OpenAI API Key is required for this chatbot to work.
                                                    They are free and can be
                                                    <a href="https://openai.com/api/" target="_blank"> obtained here</a>.
                                                    If you need help just <a target="_blank" href="https://help.openai.com/en/articles/4936850-where-do-i-find-my-secret-api-key" id="wpautoaiimages_apihelp_btn">click here for instructions</a>.
                                              </i>
                    </label>


                    <input
                      onInput={(e) => {
                        setInputValues(() => {
                          let d = { ...inputValues };
                          d.open_ai_key = e.target.value;
                          return d;
                        });
                      }}
                      type="text"
                      rows="5"
                      className="form-control"
                      id="specificSizeInputGroupUsername"
                      placeholder="sk-1234567890......"
                      value={inputValues.open_ai_key}
                    />
              </Col>
              <div className="pt-3 chat-input-section">
                <Row>
                  <Col className="col-auto"></Col>
                </Row>
              </div>
            </React.Fragment>
            </SimpleBar>
          </div>
  
          <div className="p-3 chat-input-section">
            <Row>
              <Col className="col"></Col>
              <Col className="col-auto">
                <button
                  type="submit"
                  className="btn btn-primary chat-send w-md waves-effect waves-light"
                  onClick={() => {
                    saveUserSettings(inputValues, false).then((res) => {
                      props.onSave(res);
                    });
                    console.log(inputValues);
                  }}
                >
                  <span className="d-none d-sm-inline-block me-2">Save</span>{" "}
                  <i className="mdi mdi-content-save float-end"></i>
                </button>
              </Col>
            </Row>
          </div>
        </Card>
      </div>
    );
  
}

