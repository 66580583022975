import React from "react";

import { Card, Col, Container, Row, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Button } from "reactstrap";
import { Link } from "react-router-dom";
import LinkButton from "../components/LinkButton";






export default function PersonalityCard(props) {

    return (
        <li className="active">
        <LinkButton onClick={props.onClick} target={""} style={{
    position: 'relative',
    display: 'block',
    color: '#74788d',
    transition: 'all 0.4s',
    padding: '12px',
    borderTop: '1px solid #eff0f2',
    borderRadius: '4px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    fontSize: '14px',
    fontWeight: 500
        }}>
            <div className="d-flex align-items-center">
                <div className="flex-shrink-0 me-3">
                    <div className="avatar-sm">
                        <span className="avatar-title rounded-circle bg-soft-primary text-primary">
                            {props.name[0]}
                        </span>
                    </div>
                </div>

                <div className="flex-grow-1">
                    <h5 className="font-size-14 mb-0">{props.name}</h5>
                </div>
            </div>
        </LinkButton>
    </li>
    );

}