import React from "react";
import { Container, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <React.Fragment>
      <footer className="footer">
        <Container fluid ={true}>
          <Row>
            <Col sm={6}>{new Date().getFullYear()} © AiBizChats.com</Col>
            <Col sm={6}>
              <div className="text-sm-end d-none d-sm-block">           
                <Link  to={`/terms-of-service`}  className="text-reset">Terms of Service</Link>
                &nbsp; | &nbsp;
                <Link to={`/privacy-policy`}  className="text-reset">Privacy Policy</Link>
                &nbsp; | &nbsp;
                <Link to={`/disclaimers`}  className="text-reset">Disclaimers</Link>
                &nbsp; | &nbsp;
                <Link to={`/contact-us`}  className="text-reset">Contact Us</Link>
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
    </React.Fragment>

  );
}

export default Footer;