import React, {useState, useRef, useEffect} from 'react';

import {useLocation, useNavigate} from 'react-router-dom';

import {
    Card, Col, Container, Row, UncontrolledDropdown,
    Toast, ToastHeader, ToastBody, Button,
    DropdownToggle, DropdownMenu, DropdownItem
} from "reactstrap";
import {CardBody, Collapse, Nav, NavItem, NavLink, TabContent, TabPane, CardHeader} from "reactstrap";
import classnames from "classnames";

import {Link} from "react-router-dom";

import logosm from '../../assets/images/logo.png';

//Simple bar
import SimpleBar from "simplebar-react";
import {apiRoot, deleteDataIndex, getDataSourcesLoadingProgress} from '../../Api';
// Import Images


import img1 from '../../assets/images/small/img-1.jpg';
import img2 from '../../assets/images/small/img-2.jpg';

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import {Breadcrumb, BreadcrumbItem} from "reactstrap";

import DataSourceCard from "./components/DataSourceCard";
import PersonalityCard from "./components/PersonalityCard";
import {getDataIndex, askBot, generateMemoryKey, newDataIndex} from "../../Api";
import {useId,} from 'react';
import ChatBubble from './components/ChatBubble';
import DataSourceEditor from './components/DataSourceEditor';
import SummaryEditor from './components/SummaryEditor';
import PersonalityEditor from './components/PersonalityEditor';
import EmbedEditor from './components/EmbedEditor';
import OpenAiAccountEditor from './components/OpenAiAccountEditor';
import LinkButton from "./components/LinkButton";
import Chat from "./Chat";
import "../../assets/scss/embed_bot.css";

const AIManager = (props) => {
    document.title = "AiBizChat.com | Manage Chatbot";

    const editModes = {
        embed: "embed",
        dataSource: "dataSource",
        personality: "personality",
        settings: "settings",
        summary: "summary",
        open_ai_account: "open_ai_account",
    }
    let navigate = useNavigate();

    const [dataIndexes, setDataIndexes] = useState([]);
    const [showMessage, setShowMessage] = useState(null);

    const toggleShowMessage = (message) => {
        setShowMessage(message);
        setTimeout(() => {
            setShowMessage(null);
        }, 1750);
    }
    const [editMode, setEditMode] = useState(null);
    const [dataSource, setDataSource] = useState(null);
    const [dataIndex, setDataIndex] = useState(null);
    const [messages, setMessages] = useState([]);
    const id = useId();
    const [input, setInput] = useState(props?.value ?? '');
    const checkLoaders = () => {
        console.log("test")
        getDataSourcesLoadingProgress(location.state.dataIndexId).then((res) => {
            if (dataIndex) {
                res.progresses.forEach((dataSource) => {
                    dataIndex.data_sources.forEach((data_source) => {
                        if (data_source.id === dataSource.id) {
                            data_source.percent_complete = dataSource.percent_complete;
                        }

                    })
                })
                setDataIndex(dataIndex);
            }

            setTimeout(() => checkLoaders(), 2000);
        });

    }


    const refreshDataIndex = (dataIndexId) => {

        let t = 1;
        if (!dataIndexId) dataIndexId = dataIndex.id;
        getDataIndex(dataIndexId).then((res) => {
            setDataIndexes(res);

            let newDataIndexRefresh = res.filter((dataIndex) => {
                return dataIndex.id === dataIndexId;
            })[0];

            setDataIndex(newDataIndexRefresh);

        });
    }


    let location = useLocation();
    useEffect(() => {
        if (location.state && (!dataIndex || location.state.dataIndexId !== dataIndex.id)) {


            if (location.state.dataIndexId == "new") {
                newDataIndex().then(function (res) {
                    let newIndex = res.index;
                    location.state.dataIndexId = newIndex.id;
                    setDataIndex(newIndex);
                });

            } else {
                refreshDataIndex(location.state.dataIndexId);


                setDataSource(null);


            }


        }
    }, []);

    useEffect(() => {
        getDataIndex(location.state.dataIndexId).then((res) => {
            setDataIndexes(res);

        });

    }, []);


    const [customActiveTab, setcustomActiveTab] = useState("1");

    const toggleCustom = (tab) => {
        if (customActiveTab !== tab) {
            setcustomActiveTab(tab);
        }
    };


    const messagesEndRef = useRef(null)

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({behavior: "smooth"})
    }

    useEffect(() => {
        scrollToBottom()
    }, [messages]);


    if (!dataIndex) {
        return (
            <div className="page-content">
                <Container fluid={true}
                           style={{height: "50vh", display: "flex", justifyContent: "center", alignItems: "center"}}>
                    <LinkButton to="#" className="text-primary">

                        <i className="mdi mdi-loading mdi-spin font-size-20 align-middle me-2"></i> Loading
                        Your Chatbot...
                    </LinkButton>
                </Container>
            </div>
        )
    }


    return (
        <React.Fragment>

            <div className="page-content">
                <Container fluid={true}>
                    <Breadcrumbs title="Dashboard" url="/my-bots" pageTitle={"Manage Chatbot"}
                                 breadcrumbItem={dataIndex.name}/>

                    <React.Fragment>
                        <Row>
                            <Col className="col-12">
                                <div className="page-title-box d-flex align-items-center justify-content-between">

                                    {showMessage ? <Toast
                                        style={{zIndex: 238238, margin: "auto", left: "40%", "position": "absolute"}}
                                        isOpen={showMessage !== null}
                                        className="toast fade show" role="alert">
                                        <ToastHeader toggle={() => setShowMessage(null)} className="toast-header">
                                            <img src={logosm} alt="" className="me-2" height="18"/>
                                        </ToastHeader>
                                        <ToastBody>
                                            {showMessage ? (showMessage.message ? showMessage.message : showMessage) : null}
                                        </ToastBody>
                                    </Toast> : null}

                                </div>
                            </Col>
                        </Row>
                    </React.Fragment>


                    <div className="d-lg-flex">
                        <Card className="chat-leftsidebar" style={{"maxHeight": "80vh"}}>


                            <div className="pb-3">


                                <div className="p-4 border-top">
                                    <div>

                                        <h5 className="font-size-16 mb-3"> Chatbot Settings</h5>

                                        <ul className="list-unstyled chat-list group-list">
                                            <PersonalityCard
                                                onClick={() => {
                                                    setEditMode("")
                                                }}
                                                name="Test Your Chatbot"
                                            />
                                            <hr></hr>
                                            <PersonalityCard
                                                onClick={() => {
                                                    setEditMode(editModes.settings)
                                                }}
                                                name="Edit Name & Avatar"
                                            />
                                            <hr></hr>
                                            <PersonalityCard
                                                onClick={() => {
                                                    setEditMode(editModes.embed)
                                                }}
                                                name="Copy Embed"
                                            />
                                            <hr></hr>
                                            <PersonalityCard
                                                onClick={() => {
                                                    setEditMode(editModes.open_ai_account)
                                                }}
                                                name="Your OpenAI Account"
                                            />

                                        </ul>
                                    </div>
                                </div>

                                <div className="p-4 border-top">
                                    <div>
                                        <div className="float-end">
                                            <LinkButton onClick={() => {
                                                setEditMode(editModes.dataSource)
                                                setDataSource({
                                                    id: "new",
                                                    data_index_id: dataIndex.id,
                                                    name: "",
                                                    type: "TextDataSource"
                                                })
                                            }}
                                                        to="#" className="text-primary"><i className="mdi mdi-plus"></i>
                                                New Data Source
                                            </LinkButton>
                                        </div>
                                        <h5 className="font-size-16 mb-3"> Data Sources</h5>
                                        <SimpleBar className="chat-message-list"
                                                   data-simplebar>
                                            <ul className="list-unstyled chat-list">


                                                {
                                                    dataIndex ? dataIndex.data_sources.map((dataSource) => {
                                                            return (
                                                                <DataSourceCard
                                                                    key={"data_source_" + dataSource.id}
                                                                    dataSource={dataSource}
                                                                    onClick={() => {
                                                                        setEditMode(editModes.dataSource)
                                                                        setDataSource(dataSource)
                                                                    }}
                                                                />
                                                            )

                                                        })
                                                        : null
                                                }

                                                <li onClick={() => {
                                                }}>
                                                    <LinkButton to="#" onClick={() => {
                                                        if (window.confirm("Are you sure you want to delete this chatbot?")) {
                                                            deleteDataIndex(dataIndex.id).then((res) => {
                                                                navigate("/my-bots");
                                                            });
                                                        }
                                                    }}>
                                                        <div className="d-flex align-items-start">
                                                            <div className="flex-shrink-0 me-3 align-self-center">
                                                                <div className="user-img online">
                                                                    <div className="avatar-sm">
                                                                        <span
                                                                            className="avatar-title rounded-circle bg-soft-danger text-danger">
                                                                            <i style={{
                                                                                margin: "0 auto",
                                                                                width: "50%",
                                                                                height: "75%",
                                                                            }} className={"mdi mdi-trash-can"}></i>
                                                                       </span>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div
                                                                className="flex-grow-1 overflow-hidden align-self-center">
                                                                <h5 className="text-truncate font-size-14 mb-1 align-self-center text-danger">Delete
                                                                    Chatbot</h5>

                                                            </div>

                                                            <div className="flex-shrink-0">
                                                                <div className="font-size-11"></div>
                                                            </div>
                                                        </div>
                                                    </LinkButton>
                                                </li>
                                            </ul>
                                        </SimpleBar>
                                        <SimpleBar>
                                            <ul className="list-unstyled">

                                            </ul>
                                        </SimpleBar>
                                    </div>
                                </div>


                            </div>
                        </Card>
                        {
                            editMode === editModes.summary ? (
                                    <SummaryEditor
                                        dataIndex={dataIndex}
                                        onSave={(res) => {
                                            toggleShowMessage(res);
                                            refreshDataIndex();
                                            setEditMode(null);
                                        }}
                                    />
                                ) :
                                editMode === editModes.settings ? (
                                        <PersonalityEditor
                                            dataIndex={dataIndex}
                                            onSave={(res) => {
                                                toggleShowMessage("Data Index Settings Saved");
                                                refreshDataIndex();
                                                setEditMode(null);

                                            }}
                                        />
                                    ) :
                                    editMode === editModes.embed ? (
                                            <EmbedEditor
                                                dataIndex={dataIndex}
                                                onSave={(res) => {
                                                    toggleShowMessage("Embed Settings Saved");
                                                    refreshDataIndex();
                                                    setEditMode(null);

                                                }}
                                            />
                                        ) :
                                        editMode === editModes.open_ai_account ? (
                                                <OpenAiAccountEditor

                                                    onSave={(res) => {
                                                        toggleShowMessage("OpenAI Account Settings Saved.");
                                                        refreshDataIndex();
                                                        setEditMode(null);

                                                    }}
                                                />
                                            ) :

                                            dataSource && editMode === editModes.dataSource ? (
                                                    <div className="w-100 user-chat mt-1 mt-sm-0 ms-lg-3">
                                                        <Card>
                                                            <div className="p-3 px-lg-4 border-bottom">
                                                                <Row>
                                                                    <Col md={4} className="col-6">
                                                                        <h5 className="font-size-16 mb-1 text-truncate">
                                                                            <LinkButton to="#" className="text-dark">
                                                                                {dataSource.id == "new" ? "New Data Source" : "Data Source: " + dataSource.type.replace("DataSource", "")}
                                                                            </LinkButton>
                                                                        </h5>
                                                                        <p className="text-muted text-truncate mb-0"></p>
                                                                    </Col>
                                                                    <Col md={8} className="col-6">

                                                                    </Col>
                                                                </Row>
                                                            </div>

                                                            <div className="px-lg-2">
                                                                <SimpleBar style={{height: "calc(100vh - 290px)"}}
                                                                           className="chat-conversation p-3" data-simplebar>
                                                                    <DataSourceEditor dataSource={dataSource}
                                                                                      onSave={(res) => {
                                                                                          toggleShowMessage(res);
                                                                                          refreshDataIndex();
                                                                                          setEditMode(null);

                                                                                      }}/>
                                                                </SimpleBar>
                                                            </div>

                                                        </Card>
                                                    </div>

                                                ) :
                                                (dataIndex ?


                                                    <Chat dataIndex={dataIndex}></Chat>

                                                    :
                                                    <div className="w-100 user-chat mt-4 mt-sm-0 ms-lg-3">
                                                        <Card>
                                                            <div className="p-3 px-lg-4 border-bottom">
                                                                <h3>Please select a data index.</h3>
                                                            </div>
                                                        </Card>
                                                    </div>)
                        }
                    </div>
                </Container>
            </div>
        </React.Fragment>
    );
};
export default AIManager;